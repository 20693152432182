import { combineReducers } from '@reduxjs/toolkit';
import tenantReducer from '@slices/tenant';
import projectReducer from '@modules/projects/state/slices/project';
import organizationReducer from '@modules/organizations/state/slices/organization';
import projectTagRootReducer from '@modules/projects/state/slices/project-tag-root';
import projectOrganizationRoleReducer from '@modules/projects/state/slices/project-organization-role';
import organizationTagRootReducer from '@modules/organizations/state/slices/organization-tag-root';
import infrastructureReducer from '@modules/infrastructures/state/slices/infrastructure';
import infrastructureTagRootReducer from '@modules/infrastructures/state/slices/infrastructure-tag-root';
import iframeReducer from '@slices/iframe';

export const rootReducer = combineReducers({
  tenant: tenantReducer,
  iframe: iframeReducer,
  project: projectReducer,
  organization: organizationReducer,
  infrastructure: infrastructureReducer,
  'project-tag-root': projectTagRootReducer,
  'project-organization-role': projectOrganizationRoleReducer,
  'organization-tag-root': organizationTagRootReducer,
  'infrastructure-tag-root': infrastructureTagRootReducer,
});
