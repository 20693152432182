import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  showChangeRequests: false,
};

const slice = createSlice({
  name: 'infrastructure',
  initialState,
  reducers: {
    setInfrastructure(state, action) {
      const { id, showChangeRequests } = action.payload ?? {};
      // eslint-disable-next-line no-param-reassign
      state.id = id;
      // eslint-disable-next-line no-param-reassign
      state.showChangeRequests = showChangeRequests ?? false;
    },
  },
});

export const { setInfrastructure } = slice.actions;

export const selectCurrentInfrastructure = (state) => state.infrastructure;

export default slice.reducer;
